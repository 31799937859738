<template>
  <div>
    <!-- SECTION - BANNER -->
    <div class="banner">
      <ByteArkPlayerContainer
        v-if="bannerType === 'live'"
        :options="playerOptions"
      />
      <!-- <div class="video-container">
        <iframe
          class="video-player"
          src="https://www.youtube.com/embed/5qap5aO4i9A"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div> -->
      <carousel
        v-if="bannerType === 'image'"
        :perPage="1"
        loop
        autoplay
        :autoplayHoverPause="false"
        :autoplayTimeout="4000"
      >
        <slide v-for="banner in bannerList" :key="banner.banner_id">
          <div
            class="mx-auto slide-container"
            @click="goToBannerLink(banner.href)"
          >
            <div class="thumbnail-container">
              <img v-if="banner.url" :src="banner.url" alt="" />
              <div v-else></div>
            </div>
          </div>
        </slide>
      </carousel>
      <!-- <div class="promotion">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <h2 class="mr-3">เข้าร่วมหลักสูตร</h2>
                <div>
                  <img class="logo" src="../assets/images/logo.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <p class="text-big">
                แหล่งความรู้ทางด้านการเงินสำหรับประชาชนทั่วไป<br
                  class="d-none d-md-block"
                />
                และนักศึกษาแบบครบวงจร เพื่อหาความรู้ในการพัฒนาตนเอง
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="pl-3" style="border-left: 7px solid #07C5F8">
                <h4 class="color-secondary">
                  เริ่มสมัครคอร์สเรียนวันนี้ 787 THB / Monthly
                </h4>
                <h2 class="color-secondary">
                  ลดทันที 30% โปรโมชั่นนี้ ภายในวันนี้
                </h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="d-flex my-3 subscribe text-big">
                <input type="email" placeholder="email address" />
                <button>Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- SECTION - All Curriculum -->
    <div class="container">
      <div class="filter">
        <div class="row align-items-center">
          <div class="col-12 col-lg-3">
            <div class="d-flex align-items-center">
              <div class="position-relative w-100" style="margin-right: 10px">
                <input
                  type="text"
                  placeholder="search"
                  class="w-100"
                  v-model="searchTerm"
                  v-debounce:500ms="queryCurriculum"
                />
                <img
                  class="position-absolute"
                  style="right: 10px; top:50%; transform: translateY(-50%)"
                  src="../assets/images/search.svg"
                  alt=""
                />
              </div>
              <img
                class="d-block d-lg-none"
                style="cursor: pointer;"
                src="../assets/images/filter.svg"
                alt=""
                @click="toggleFilter"
              />
            </div>
          </div>
          <div
            class="col-12 col-lg-9 checkbox-container"
            :class="{ 'show-filter': showFilter }"
          >
            <div class="d-flex flex-wrap flex-column flex-lg-row mt-3 mt-lg-0">
              <CustomCheckbox
                class="mr-3"
                label="หลักสูตรทั้งหมด"
                :checked="allCourse"
                @toggleCheck="toggleCheckbox"
              />
              <CustomCheckbox
                class="mr-3"
                v-for="filter in filterList"
                :key="filter.id"
                :label="filter.title"
                :checked="isInFilter(filter.title)"
                @toggleCheck="toggleCheckbox"
              />
              <CustomCheckbox
                label="หลักสูตรเฉพาะกลุ่ม"
                :checked="organizationCourse"
                @toggleCheck="toggleCheckbox"
              />
            </div>
          </div>
        </div>
      </div>
      <h4 class="font-weight-bold mr-3 mb-3">หลักสูตรทั้งหมด</h4>
      <div v-if="curriculumList.length > 0" class="row">
        <div
          v-for="curriculum in curriculumList"
          :key="curriculum.id"
          class="col-12 col-md-4 my-2"
        >
          <CourseCard :course="curriculum" type="curriculum" />
        </div>
      </div>
      <div v-else>
        <h3 class="color-text text-center">ไม่มีคอร์สที่จะแสดง</h3>
      </div>
      <p
        v-if="curriculumTotal > curriculumList.length"
        class="see-more color-secondary text-big font-weight-bold"
        @click="loadMoreCourse"
      >
        ดูเพิ่มเติม
      </p>
    </div>
  </div>
</template>

<script>
import ByteArkPlayerContainer from "@byteark/byteark-player-vue";
import CourseCard from "../components/CourseCard.vue";
import CustomCheckbox from "../components/CustomCheckbox.vue";
import axios from "../api/faAPI";
import Qs from "qs";
import baseUrl from "../util/backend";
export default {
  components: {
    CourseCard,
    CustomCheckbox,
    ByteArkPlayerContainer,
  },
  data() {
    return {
      bannerList: null,
      filterList: [],
      // ----- for filter ---- //
      filterArr: [],
      searchTerm: "",
      allCourse: true,
      organizationCourse: false,
      showFilter: false,
      // <<-- for filter -->> //
      curriculumList: [],
      page: 1,
      curriculumTotal: 0,
      bannerType: "image",
      playerOptions: {
        autoplay: true,
        fluid: true,
        aspectRatio: "16:9",
        autoplay: true,
        // poster:
        //   "https://qoder.byteark.com/images/video-frames/1/GU/cg/1GUcgd3XwsmD-large.jpg",
        sources: {
          src: "",
          type: "application/x-mpegURL",
          // Optional
          title: "LIVE",
          videoId: "RI2PimuHxDXw",
          // poster:
          //   "https://qoder.byteark.com/images/video-frames/1/GU/cg/1GUcgd3XwsmD-large.jpg",
        },
      },
    };
  },
  watch: {
    filterArr() {
      this.page = 1;
      this.curriculumList = [];
      if (this.filterArr.length > 0) {
        console.log("have filter");
        this.allCourse = false;
      } else {
        console.log("no filter");
        this.allCourse = true;
      }
      this.queryCurriculum();
    },
    searchTerm() {
      this.page = 1;
    },
  },
  methods: {
    isInFilter(name) {
      if (this.filterArr.includes(name)) return true;
      else return false;
    },
    goToBannerLink(link) {
      window.open(`${link}`, "_blank");
    },
    toggleCheckbox(value, name) {
      if (name !== "หลักสูตรทั้งหมด") {
        if (value) {
          this.filterArr.push(name);
        } else {
          this.filterArr = this.filterArr.filter((x) => x !== name);
        }
      }
      if (name === "หลักสูตรเฉพาะกลุ่ม") {
        this.organizationCourse = value;
      }
      if (name === "หลักสูตรทั้งหมด") {
        if (value) {
          this.filterArr = [];
          this.organizationCourse = false;
        }
      }
      console.log(this.filterArr);
    },
    queryCurriculum() {
      this.page = 1;
      this.curriculumList = [];
      this.loadCourse();
    },
    async loadCourse() {
      if (this.searchTerm || this.filterArr.length > 0) {
        try {
          const res = await axios.get(`${baseUrl}/curriculumFilter`, {
            paramsSerializer: (params) =>
              Qs.stringify(params, { arrayFormat: "repeat" }),
            params: {
              page: this.page,
              limit: 9,
              name: this.searchTerm ? this.searchTerm : undefined,
              category: this.filterArr,
              user_id: this.$store.getters.getUser
                ? this.$store.getters.getUser.user_id
                : undefined,
            },
          });
          console.log(res.data);
          this.curriculumList = [...this.curriculumList, ...res.data.data];
          this.curriculumTotal = res.data.count;
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      } else {
        try {
          const res = await axios.get(`${baseUrl}/getAllCurriculum`, {
            params: {
              page: this.page,
              limit: 9,
              user_id: this.$store.getters.getUser
                ? this.$store.getters.getUser.user_id
                : undefined,
            },
          });
          console.log(res.data);
          this.curriculumList = [...this.curriculumList, ...res.data.data];
          this.curriculumTotal = res.data.count;
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      }
    },
    async loadMoreCourse() {
      this.page += 1;
      this.loadCourse();
    },
    async loadFilterList() {
      let filterList = [];
      const res = await axios.get(`${baseUrl}/getAllCategoryCurriculum`);
      filterList = res.data.data;
      filterList = filterList.filter((x) => x.type === "หลักสูตรหลัก");
      this.filterList = filterList;
      console.log("filter", filterList);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    async loadBanner() {
      try {
        const res = await axios.get(`${baseUrl}/getBanner`);
        this.bannerType = res.data.type;
        if (res.data.type === "image") {
          this.bannerList = res.data.data;
        }
        if (res.data.type === "live") {
          this.playerOptions.sources.src = res.data.data.url;
        }
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
    },
  },
  created() {
    this.loadCourse();
    this.loadBanner();
    this.loadFilterList();
  },
};
</script>

<style scoped>
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

.thumbnail-container {
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-top: 75%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.thumbnail-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}

.banner {
  margin-bottom: 50px;
}

.banner >>> .VueCarousel-dot-container[style] {
  margin: 0 !important;
}
.banner >>> .VueCarousel-dot.VueCarousel-dot--active[style] {
  background-color: #07c5f8 !important;
  outline: none;
}
.banner >>> .VueCarousel-dot {
  background-color: #c4c4c4 !important;
  outline: none;
  margin: 0 !important;
}

.filter {
  margin: 50px 0;
}

.filter input[type="text"] {
  outline: none;
  border: 1px solid #798da5;
  padding: 10px;
}

.filter input[type="checkbox"] {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}

.checkbox-container {
  height: 0;
  overflow: hidden;
  transition: all 1s;
}

.show-filter {
  height: auto;
}

.see-more {
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  margin: 10px;
}

@media only screen and (min-width: 768px) {
  /* .slide-container {
    max-width: 700px;
  }
  .banner >>> .VueCarousel-slide {
    padding: 30px 0;
  } */
  .banner >>> .VueCarousel-wrapper {
    background: linear-gradient(90deg, #bfeaf5 0%, #ecf1ff 75.52%);
  }
  .thumbnail-container {
    cursor: pointer;
    position: relative;
    width: 100%;
    padding-top: 33.33%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
}

@media only screen and (min-width: 992px) {
  .checkbox-container {
    height: auto;
    overflow: hidden;
  }
}
</style>
